import { combineReducers } from 'redux';
import languageReducer from './ducks/languageDuck';
import currSelectionReducer from './ducks/currencySelectionDuck';
import nativeNetworkReducer from './ducks/nativeNetworkDuck';
const rootReducer = combineReducers({
  currSelection: currSelectionReducer,
  language: languageReducer,
  nativeNetwork: nativeNetworkReducer
  // Add other reducers here if needed
});

export default rootReducer;
