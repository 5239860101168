import React from "react";

const VideoHeader = ({path, text}) => {
    return (
        <video className="header-video" autoPlay loop muted playsInline>
            <source src={path} type="video/mp4" />
            {text}
        </video>
    );
};

export default VideoHeader;
