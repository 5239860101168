import React, { useState, useEffect } from 'react';
import "./about.css";
import VideoHeader from '../video';
import { useI18nSection } from "../../utils/languageUtils";
import { height, width } from '@fortawesome/free-solid-svg-icons/fa0';
import Navbar from '../nav/Navbar';
import NotificationBar from '../notificationBar/NotificationBar';
import BottomFooter from '../footer/BottomFooter';

export const About = () => {
    const sectionText = useI18nSection('about')
    const [isMobile, setIsMobile] = useState(false);
    const [isNotificationVisible, setIsNotificationVisible] = useState(true);

    const handleNotificationClose = () => {
      setIsNotificationVisible(false);
    };
  
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as needed
        };

        handleResize(); // Check initial viewport width
        window.addEventListener('resize', handleResize); // Add event listener for window resize

        return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
    }, []);
    return (
        <div className='wp-site-blocks'>
        {/* {isNotificationVisible && <NotificationBar onClose={handleNotificationClose} />} */}
       
            <Navbar topOffset={isNotificationVisible ? 60 : 20} />
     

            {/* end header */}
            {/* start video */}
            <VideoHeader path={"https://hemi.xyz/wp-content/themes/hemi/video/header_3840.mp4"} text={"abc"} />
            {/* end video */}
            {/* start video att */}
            <div className="video-attachment">
            <div className="front-page-title">
                One Network, Powered by  
                <div>
                    <span> </span> 
                    <img src="https://hemi.xyz/wp-content/themes/hemi/images/front/title/btc.svg" />
                    <span> Bitcoin </span>
                </div>
                <span> and </span>
                <div>
                    <span> </span>
                    <img src="https://hemi.xyz/wp-content/themes/hemi/images/front/title/eth.svg" />
                    <span> Ethereum.</span>
                </div>
  
            </div>
                <h3 className="wp-block-hemi-genericheading headline headline--small">Hemi is a modular blockchain built for superior
                    scaling, security and interoperability.</h3>

                <div className="row front-page-header-buttons" >
                    <a className="hemi-button hemi-button--primary" target="_blank" href="/app">
                        Launch App</a>

                    <a className="hemi-button" target="_blank" href="https://discord.gg/hemixyz">
                        <img src="https://hemi.xyz/wp-content/themes/hemi/images/icon/discord.svg" className="hemi-button__icon" />
                        Join the Discord</a>
                </div>
            </div>
            {/* end video att */}
            {/* start sponsors */}
            <div className="sponsors">
                <h3 className="wp-block-hemi-genericheading headline headline--small">Supported by:</h3>
                <div className="sponsors-top">
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/binance.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/breyer-capital.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/big-brain-holdings.png" /></div>
                </div>
                <div className="sponsors-separator">
                </div>
                <div className="sponsors-bottom">
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/01.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/02.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/03.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/04.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/05.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/06.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/07.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/08.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/09.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/10.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/11.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/12.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/13.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/14.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/15.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/16.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/17.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/18.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/19.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/20.png" /></div>
                    <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/sponsor/21.png" /></div>
                </div>
            </div>
            {/* end sponsors */}
            {/* start The Hemi Virtual Machine */}
            <div className="section-decorator  display-elipse-top display-elipse-bottom">
                <div className="shadow-top"></div>
                <div className="line"></div>  <section>
                    <div className="virtual-machine">
                        <VideoHeader path={"https://hemi.xyz/wp-content/themes/hemi/video/core.mp4"} text={"xyz"} />
                        <div className="section-header">
                            <h3 className="wp-block-hemi-genericheading headline headline--small">Bringing Bitcoin and Ethereum together.</h3>
                            <h1 className="wp-block-hemi-genericheading headline headline--large">The Hemi Virtual Machine</h1>
                            <p>The Hemi VM (hVM) is a full Bitcoin node wrapped inside an EVM, the best way to deliver the capabilities of both networks.</p>
                            <div className="section-header-buttons">
                                <a className="hemi-button hemi-button--primary hemi-button--icon-right" target="_blank" href="https://docs.hemi.xyz/building-bitcoin-apps/hemi-virtual-machine-hvm">
                                    <img src="https://hemi.xyz/wp-content/themes/hemi/images/icon/arrow.svg" className="hemi-button__icon" />
                                    Learn more</a>  </div>
                        </div>
                    </div>
                    <div className="benefits">
                        <div className="section-header">
                            <h3 className="wp-block-hemi-genericheading headline headline--small">A look inside</h3>
                            <h1 className="wp-block-hemi-genericheading headline headline--large">The Unique Benefits of Hemi</h1>
                            <div className="section-header-buttons">
                                <a className="hemi-button hemi-button--primary hemi-button--icon-right" target="_blank" href="https://docs.hemi.xyz">
                                    <img src="https://hemi.xyz/wp-content/themes/hemi/images/icon/arrow.svg" className="hemi-button__icon" />Learn more
                                </a>
                            </div>
                        </div>
                        <div className="benefits-list">
                            <p><strong>Superfinality.</strong> Proof-of-Proof security delivers superior decentralization and better-than-Bitcoin finality in just a couple of hours.</p>
                            <p><strong>Portability.</strong> Move assets between the Bitcoin and Ethereum networks via Hemi’s Tunnels</p>
                            <p><strong>Programmability.</strong> Build better Bitcoin-aware, EVM-compatible applications with the Hemi Bitcoin Kit (hBK).</p>
                            <p><strong>Extensibility.</strong> Incorporate Bitcoin’s security into any blockchain network, delivering a more secure and reliable Web3 ecosystem. (Q4-2024)”</p>
                        </div>
                    </div>
                </section>
                <div className="line"></div>
                <div className="shadow-bottom"></div>
            </div>
            {/* end The Hemi Virtual Machine */}
            {/* start ecosystem */}
            <div className="ecosystem-banner">
                <div className="section-header">
                    <h3 className="wp-block-hemi-genericheading headline headline--small">Hemi ecosystem</h3>
                    <h1 className="wp-block-hemi-genericheading headline headline--large">Join a vibrant and growing ecosystem</h1>
                    <div className="section-header-buttons">
                        <a className="hemi-button hemi-button--primary hemi-button--icon-right" target="" href="/ecosystem">
                            <img src="https://hemi.xyz/wp-content/themes/hemi/images/icon/arrow.svg" className="hemi-button__icon" />
                            Ecosystem</a>
                        <a className="hemi-button hemi-button--icon-right" target="_blank" href="https://forms.gle/6fJ8W6WtJ5MxaWQp6">
                            <img src="https://hemi.xyz/wp-content/themes/hemi/images/icon/arrow.svg" className="hemi-button__icon" />
                            Submit your project</a>  </div>
                </div>
                <div className="ecosystem-banner-icons">
                    <div className="row">
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/pure.svg" /></div>
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/absinthe.svg" /></div>
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/metamask.svg" /></div>
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/blockscout.svg" /></div>
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/unisat.svg" /></div>
                    </div>
                    <div className="row">
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/rainbow.svg" /></div>
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/safe.svg" /></div>
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/optimism.svg" /></div>
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/btcd.png" /></div>
                        <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/ecosystem/icons/empty.svg" /></div>
                    </div>
                </div>
            </div>
            {/* end ecosystem */}
            {/* start news */}
            <div className="section-decorator  display-elipse-top display-elipse-bottom">
                <div className="shadow-top"></div>
                <div className="line"></div>
                <section>
                    <div className="in-the-news">
                        <div className="banner">
                            <div className="banner-bg-image"></div>
                            <div className="banner-content">
                                <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/in-the-news.svg" /></div>
                                <div className="image-frontend"><img src="https://hemi.xyz/wp-content/themes/hemi/images/bloomberg.svg" /></div>
                                <p>“Bitcoin programmability is increasing – by virtue of these new Bitcoin L2s expanding Bitcoin’s reach.”</p>
                                <p><strong>Jeff Garzik</strong>, Hemi Labs</p>
                                <a className="hemi-button hemi-button--icon-right" target="_blank" href="https://news.bloomberglaw.com/banking-law/bitcoin-boosters-tout-programmability-as-next-rally-catalyst">
                                    <img src="https://hemi.xyz/wp-content/themes/hemi/images/icon/arrow.svg" className="hemi-button__icon" />
                                    Read the article</a>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="line"></div>  <div className="shadow-bottom"></div>
            </div>
            {/* end news */}
            {/* start social */}
            <div className="connect-with-us">
                <div className="section-header">
                    <h3 className="wp-block-hemi-genericheading headline headline--small">Let’s get social</h3>
                    <h1 className="wp-block-hemi-genericheading headline headline--large">Connect with us</h1>
                    <div className="section-header-buttons">
                    </div>
                </div>
                <ul className="hemi-social-menu">
                    <li>
                        <a  href="https://x.com/hemi_xyz" title="Hemi X account" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
                                <path d="M25.101 2.25H29.4239L19.9796 13.0515L31.0901 27.75H22.3907L15.5771 18.8355L7.78065 27.75H3.45513L13.5567 16.1965L2.89844 2.25H11.8187L17.9777 10.3982L25.101 2.25ZM23.5838 25.1607H25.9791L10.5171 4.70323H7.94663L23.5838 25.1607Z" fill="#808080"></path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a  href="https://discord.gg/hemixyz" title="Hemi Discord server" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M14.5878 4.00498C13.5531 3.52873 12.4606 3.19086 11.3385 3C11.1849 3.27595 11.046 3.55987 10.9223 3.85056C9.72697 3.66949 8.51144 3.66949 7.31615 3.85056C7.19234 3.5599 7.05341 3.27598 6.89992 3C5.77706 3.19247 4.68393 3.53114 3.64809 4.00747C1.5917 7.066 1.03425 10.0486 1.31298 12.9888C2.51725 13.8833 3.86518 14.5635 5.29816 15C5.62083 14.5637 5.90635 14.1009 6.15169 13.6164C5.6857 13.4415 5.23593 13.2256 4.8076 12.9714C4.92033 12.8892 5.03058 12.8045 5.13712 12.7223C6.38346 13.3115 7.74378 13.617 9.12107 13.617C10.4984 13.617 11.8587 13.3115 13.105 12.7223C13.2128 12.8107 13.323 12.8954 13.4345 12.9714C13.0054 13.226 12.5548 13.4423 12.088 13.6177C12.333 14.1019 12.6185 14.5644 12.9415 15C14.3757 14.5653 15.7247 13.8853 16.9292 12.99C17.2562 9.58032 16.3705 6.62516 14.5878 4.00498ZM6.50474 11.1806C5.72802 11.1806 5.08633 10.472 5.08633 9.60025C5.08633 8.72852 5.70572 8.0137 6.50226 8.0137C7.29881 8.0137 7.93554 8.72852 7.92192 9.60025C7.90829 10.472 7.29633 11.1806 6.50474 11.1806ZM11.7374 11.1806C10.9594 11.1806 10.3202 10.472 10.3202 9.60025C10.3202 8.72852 10.9396 8.0137 11.7374 8.0137C12.5352 8.0137 13.167 8.72852 13.1533 9.60025C13.1397 10.472 12.529 11.1806 11.7374 11.1806Z" fill="#808080"></path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a  href="https://github.com/hemilabs" title="Hemi GitHub account" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.12109 1.46289C13.2648 1.46289 16.6211 4.81914 16.6211 8.96289C16.6207 10.5343 16.1275 12.0661 15.2109 13.3425C14.2944 14.619 13.0006 15.5759 11.5117 16.0785C11.1367 16.1535 10.9961 15.9191 10.9961 15.7223C10.9961 15.4691 11.0055 14.6629 11.0055 13.6598C11.0055 12.9566 10.7711 12.5066 10.4992 12.2723C12.168 12.0848 13.9211 11.4473 13.9211 8.56914C13.9211 7.74414 13.6305 7.07852 13.1523 6.55351C13.2273 6.36601 13.4898 5.59727 13.0773 4.56602C13.0773 4.56602 12.4492 4.35977 11.0148 5.33477C10.4148 5.16602 9.77734 5.08164 9.13984 5.08164C8.50234 5.08164 7.86484 5.16602 7.26484 5.33477C5.83047 4.36914 5.20234 4.56602 5.20234 4.56602C4.78984 5.59727 5.05234 6.36601 5.12734 6.55351C4.64922 7.07852 4.35859 7.75352 4.35859 8.56914C4.35859 11.4379 6.10234 12.0848 7.77109 12.2723C7.55547 12.4598 7.35859 12.7879 7.29297 13.2754C6.86172 13.4723 5.78359 13.791 5.10859 12.6566C4.96797 12.4316 4.54609 11.8785 3.95547 11.8879C3.32734 11.8973 3.70234 12.2441 3.96484 12.3848C4.28359 12.5629 4.64922 13.2285 4.73359 13.4441C4.88359 13.866 5.37109 14.6723 7.25547 14.3254C7.25547 14.9535 7.26484 15.5441 7.26484 15.7223C7.26484 15.9191 7.12422 16.1441 6.74922 16.0785C5.25544 15.5813 3.95616 14.6263 3.03568 13.3491C2.1152 12.0719 1.62027 10.5372 1.62109 8.96289C1.62109 4.81914 4.97734 1.46289 9.12109 1.46289Z" fill="#808080"></path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a  href="https://www.linkedin.com/company/hemi-labs" title="Hemi Linkedin account" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path d="M7.81934 5.00002C7.81907 5.53046 7.6081 6.03906 7.23284 6.41394C6.85758 6.78883 6.34877 6.99929 5.81834 6.99902C5.2879 6.99876 4.7793 6.78779 4.40442 6.41253C4.02953 6.03727 3.81907 5.52846 3.81934 4.99802C3.8196 4.46759 4.03057 3.95899 4.40583 3.5841C4.78109 3.20922 5.2899 2.99876 5.82034 2.99902C6.35077 2.99929 6.85937 3.21026 7.23426 3.58552C7.60914 3.96078 7.8196 4.46959 7.81934 5.00002ZM7.87934 8.48002H3.87934V21H7.87934V8.48002ZM14.1993 8.48002H10.2193V21H14.1593V14.43C14.1593 10.77 18.9293 10.43 18.9293 14.43V21H22.8793V13.07C22.8793 6.90002 15.8193 7.13002 14.1593 10.16L14.1993 8.48002Z" fill="#808080"></path></svg></a>
                    </li>
                    <li>
                        <a  href="" title="Hemi Telegram channels" target="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clipPath="url(#clip0_4993_145)"><path d="M11.944 3.26667e-05C8.77112 0.0148396 5.73324 1.28566 3.4949 3.53449C1.25656 5.78332 -3.4549e-05 8.82711 7.12435e-10 12C7.12441e-10 15.1826 1.26428 18.2349 3.51472 20.4853C5.76515 22.7358 8.8174 24 12 24C15.1826 24 18.2348 22.7358 20.4853 20.4853C22.7357 18.2349 24 15.1826 24 12C24 8.81744 22.7357 5.76519 20.4853 3.51475C18.2348 1.26431 15.1826 3.26667e-05 12 3.26667e-05C11.9813 -1.08889e-05 11.9627 -1.08889e-05 11.944 3.26667e-05ZM16.906 7.22403C17.006 7.22203 17.227 7.24703 17.371 7.36403C17.4667 7.44713 17.5277 7.56311 17.542 7.68903C17.558 7.78203 17.578 7.99503 17.562 8.16103C17.382 10.059 16.6 14.663 16.202 16.788C16.034 17.688 15.703 17.989 15.382 18.018C14.686 18.083 14.157 17.558 13.482 17.116C12.426 16.423 11.829 15.992 10.804 15.316C9.619 14.536 10.387 14.106 11.062 13.406C11.239 13.222 14.309 10.429 14.369 10.176C14.376 10.144 14.383 10.026 14.313 9.96403C14.243 9.90203 14.139 9.92303 14.064 9.94003C13.958 9.96403 12.271 11.08 9.003 13.285C8.523 13.615 8.09 13.775 7.701 13.765C7.273 13.757 6.449 13.524 5.836 13.325C5.084 13.08 4.487 12.951 4.539 12.536C4.566 12.32 4.864 12.099 5.432 11.873C8.93 10.349 11.262 9.34403 12.43 8.85903C15.762 7.47303 16.455 7.23203 16.906 7.22403Z" fill="#808080"></path></g><defs><clipPath id="clip0_4993_145"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg></a>
                        <ul>
                            <li>
                                <a href="https://t.me/hemi_community" title="Community Channel" target="_blank">
                                    <span className="title">Community Channel</span>
                                    <span className="description">Community</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://t.me/hemi_news" title="News Channel" target="_blank">
                                    <span className="title">News Channel</span>
                                    <span className="description">News</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            {/* end social */}
            {/* start the latest */}
            <div className="hemi-blog-latest">
                <div className="tag-link" >
                    <div>
                        Read the latest news and updates
                        <span>Go to Blog</span>
                    </div>
                </div>
                <h2>The Latest on Hemi</h2>
                <div className="hemi-blog-latest-posts">
                    <div className="hemi-post-latest" >
                        <div className="image-frontend">
                            <img src="https://hemi.xyz/wp-content/uploads/2024/09/HEMI-FUNDING-FINAL-526x296.jpg" /></div>
                        <div className="category-and-time">
                            <ul className="post-categories">
                                <li><a href="https://hemi.xyz/blog/category/announcements/" rel="category tag">Announcements</a></li>
                                <li><a href="https://hemi.xyz/blog/category/hemi/" rel="category tag">Hemi</a></li>
                                <li><a href="https://hemi.xyz/blog/category/press/" rel="category tag">Press Releases</a></li></ul>            <span> – Sep, 18 2024</span>
                        </div>
                    </div>
                    <div className="hemi-post-latest" >
                        <div className="image-frontend">
                            <img src="https://hemi.xyz/wp-content/uploads/2024/09/Group-46113-526x296.png" /></div>
                        <div className="category-and-time">
                            <ul className="post-categories">
                                <li><a href="https://hemi.xyz/blog/category/ethereum/" rel="category tag">Ethereum</a></li>
                                <li><a href="https://hemi.xyz/blog/category/hemi/" rel="category tag">Hemi</a></li>
                                <li><a href="https://hemi.xyz/blog/category/video/" rel="category tag">Video</a></li></ul>
                            <span> – Sep, 19 2024</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* end the latest */}
            {/* start footer */}
            {/* <footer>
                <div id="footer-container">
                    <div id="footer-top">
                        <div id="footer-top-left">
                            <div className="logo-wrapper">
                                <a href="https://hemi.xyz/" className="custom-logo-link" rel="home" aria-current="page">
                                    <img width="93" height="32" src="https://hemi.xyz/wp-content/uploads/2024/04/hemi.svg" className="custom-logo" alt="Hemi" decoding="async" />
                                </a>
                                <div className="logo-subtitle">
                                    Powered by Bitcoin and Ethereum
                                </div>
                            </div>
                            <div className="menu-social-container">
                                <ul id="menu-social" className="menu">
                                    <li id="menu-item-96" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-96">
                                        <a target="_blank" rel="noopener" href="https://x.com/hemi_xyz">
                                            <img src="https://hemi.xyz/wp-content/uploads/2024/04/twitter.svg" />
                                            <span>Hemi X account<span className="description"></span></span>
                                        </a>
                                    </li>
                                    <li id="menu-item-97" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-97">
                                        <a target="_blank" rel="noopener" href="https://discord.gg/hemixyz">
                                            <img src="https://hemi.xyz/wp-content/uploads/2024/04/discord.svg" />
                                            <span>Hemi Discord server<span className="description"></span></span>
                                        </a>
                                    </li>
                                    <li id="menu-item-98" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-98">
                                        <a target="_blank" rel="noopener" href="https://github.com/hemilabs">
                                            <img src="https://hemi.xyz/wp-content/uploads/2024/04/github.svg" />
                                            <span>Hemi GitHub account<span className="description"></span></span>
                                        </a>
                                    </li>
                                    <li id="menu-item-99" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-99">
                                        <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/hemi-labs">
                                            <img src="https://hemi.xyz/wp-content/uploads/2024/05/linkedin.svg" />
                                            <span>Hemi Linkedin account<span className="description"></span></span>
                                        </a>
                                    </li>
                                    <li id="menu-item-580" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-580">
                                        <a>
                                            <img src="https://hemi.xyz/wp-content/uploads/2024/08/telegram.svg" />
                                            <span>Hemi Telegram channels<span className="description"></span></span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li id="menu-item-611" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-611">
                                                <a target="_blank" rel="noopener" href="https://t.me/hemi_community">Community Channel</a>
                                            </li>
                                            <li id="menu-item-612" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-612">
                                                <a target="_blank" rel="noopener" href="https://t.me/hemi_news">News Channel</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id="footer-top-center">
                            <div>
                                <h3>Network</h3>
                                <div className="menu-footer-network-container"><ul id="menu-footer-network" className="menu"><li id="menu-item-480" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-480"><a href="/ecosystem">Ecosystem</a></li> <li id="menu-item-86" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86"><a target="_blank" rel="noopener" href="https://testnet.explorer.hemi.xyz/">Block explorer</a></li> </ul></div>        </div>
                            <div>
                                <h3>Company</h3>
                                <div className="menu-footer-company-container"><ul id="menu-footer-company" className="menu"><li id="menu-item-87" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-87"><a href="/about">About</a></li> <li id="menu-item-88" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-88"><a href="/blog">Blog</a></li> <li id="menu-item-91" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-91"><a href="mailto:careers@hemi.xyz">Careers</a></li> <li id="menu-item-441" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-441"><a target="_blank" rel="noopener" href="https://docs.hemi.xyz/additional-resources/brand-kit">Brand</a></li> </ul></div>        </div>
                            <div>
                                <h3>Resources</h3>
                                <div className="menu-footer-resources-container"><ul id="menu-footer-resources" className="menu"><li id="menu-item-92" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-92"><a target="_blank" rel="noopener" href="https://docs.hemi.xyz/">Docs</a></li> <li id="menu-item-93" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-93"><a target="_blank" rel="noopener" href="https://docs.hemi.xyz/building-bitcoin-apps/hemi-bitcoin-kit-hbk">Hemi Bitcoin Kit (hBK)</a></li> <li id="menu-item-95" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-95"><a target="_blank" rel="noopener" href="https://github.com/hemilabs">GitHub</a></li> <li id="menu-item-538" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-538"><a href="/changelog/">Changelog</a></li> </ul></div>        </div>
                        </div>
                        <div id="footer-top-right">
                            <h3>Subscribe for updates</h3>
                            <form id="form-66ed55b1b946b" className="form-subscription" action="#">
                                <input className="border-none outline-none focus:shadow-none focus:ring-0" type="email" />
                                <button type="submit">Submit</button>
                                <div className="success-message hide">
                                    Thanks for subscribing!
                                </div>
                            </form>
                        </div>

                    </div>

                    <div id="footer-bottom">
                        <p>© 2024 Hemi Labs, Inc.</p>
                        <div className="menu-legal-container"><ul id="menu-privacy-and-terms" className="menu"><li id="menu-item-73" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-73"><a href="/privacy-policy">Privacy</a></li> <li id="menu-item-74" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-74"><a href="/terms-of-service">Terms</a></li> </ul></div>    </div>
                </div>
            </footer> */}
            {/* end footer */}
            <BottomFooter/>
        </div >
    );
};
