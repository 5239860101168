import config from '../config.main.json'

const SET_CURR = 'currSelect/SET_CURR'; // New action type for setting language
const SET_APR = 'currSelect/SET_APR'; // New action type for setting language
const SET_CURR_VALUE = 'currSelect/SET_CURR_VALUE'; // New action type for setting language
function getRandomInRange(from, to) {
    const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
  
    // Simple hash function to get a seed from the current date
    let seed = 0;
    for (let i = 0; i < currentDate.length; i++) {
      seed += currentDate.charCodeAt(i);
    }
  
    // Seeded random function
    function seededRandom(seed) {
      const x = Math.sin(seed) * 10000;
      return x - Math.floor(x);
    }
  
    // Generate the deterministic random number in the range
    const randomValue = seededRandom(seed);
    return parseFloat((randomValue * (to - from) + from).toFixed(2));
}

export const CURR_LIST =  config.ETH.CURR_LIST.map(item => ({
    ...item,
    aprList: item.aprList.map(aprItem => ({
      ...aprItem,
      apr: getRandomInRange(aprItem.from, aprItem.to)
    }))
  }));



// Action creators
export const setCurrency = (curr) => {
    // Validate language against the valid range
    
    if (CURR_LIST.findIndex(x => x.value === curr) > -1) {
        
        return {
            type: SET_CURR,
            payload: curr,
        };
    } 
};

export const setApr = (address) => {
    // Validate language against the valid range
    
    return {
        type: SET_APR,
        payload: address,
    };
};

export const setCurrValue = (value) => {
    // Validate language against the valid range
    
    return {
        type: SET_CURR_VALUE,
        payload: value,
    };
};

export const selectCurrentCurr = state => state.currSelection.selectedCurr;
export const selectCurrentApr = state => state.currSelection.selectedApr;
export const selectCurrValue = state => state.currSelection.currValue;



// Initial state
const initialState = {
    selectedCurr: CURR_LIST[0],
    selectedApr: CURR_LIST[0].aprList[3].address,
    currValue : ''
};


// Reducer
const currSelectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURR:
            const crrIdx = CURR_LIST.findIndex(x => x.value === action.payload)
            
            return { ...state, selectedCurr: CURR_LIST[crrIdx] , selectedApr:  CURR_LIST[crrIdx].aprList[3].address, currValue: '' };

        case SET_APR:
            return { ...state, selectedApr:  action.payload  };

        case SET_CURR_VALUE:
            return { ...state, currValue:  action.payload };
  
        default:
            return state;
    }
  };
  

  export default currSelectionReducer
