import React, { useState, useEffect } from 'react';
import "./buyform.css";
import StakingForm from '../staking-form/StakingForm';

export const BuyForm = () => {
    return (
<div className="container">
    <div className="sidebar">
      <ul>
        <li><a href="/app" className="active">Staking</a></li>
        <li>
        <div> 
            <a href="/app">DEX</a>        
            <span className="label testing-label">Developing </span>
          </div>

        </li>
        <li>
          <div> 
            <a href="/app">Tools</a>        
            <span className="label testing-label">Developing </span>
          </div>
        </li>
      </ul>
    </div>
    
    <div className="content">
      <StakingForm/>
    </div>
  </div>
    );
};
