import React, { useState, useEffect } from "react";
import { BuyForm } from "../components/buyform/buyform";

const Staking = () => {

    return (
        <div id="container">
            <BuyForm />
        </div>
    );
};

export default Staking;