import React, { useState } from 'react';
import './StakingForm.css'; // Import the updated CSS file
import CurrencyDropdown from '../currency-dropdown/CurrencyDropdown';
import { useCurrentApr, useCurrentCurr, useCurrValue, useSetApr, useSetCurrValue } from '../../utils/currSelectionUtils';
import {useWalletETH, isValidNumber} from '../../services/wallet-service1'
import config from '../../config.main.json'
const StakingForm = () => {
  const walletETH = useWalletETH("eth",config )
  const inputCurr = useCurrValue()
  const setInputCurr = useSetCurrValue()

  // const [inputCurr , setInputCurr] = useState();
  const currentApr = useCurrentApr()
  const currentCurr = useCurrentCurr()

  const items = currentCurr.aprList
  const setCurrentApr  = useSetApr()

  const handleClick = (item) => {
    setCurrentApr(item.address);
  };


  const handleCurrencyInputChange = (event) => {
    
    const { value } = event.target;
    if (value === "") {
      setInputCurr("");
      return
    }
    
    // Regular expression to allow only numeric and float values
    if (/^[0-9]*[.]?[0-9]*$/.test(value) && isValidNumber(value)) {
      setInputCurr(value);
    }else{
      event.preventDefault()
    }
  };


  const handleKeyPressCurr = (event) => {
    // Allow the dot character (.) only if it doesn't already exist in the input value
    if (event.key === '.' && inputCurr.includes('.')) {
      event.preventDefault();
    }
  };

  const getMaxAmount = async() => {
    if(currentCurr.value === 'ETH'){
        setInputCurr(walletETH.maxAmount)
    }
    else{
        
        const balance =await walletETH.getTokenBalance(currentCurr.contract, currentCurr.decimals);
        
        setInputCurr(balance)
    }
  }

  const handleStakeOnlick = async() => {
    if(!isValidNumber(inputCurr) && Number(inputCurr) > 0){
      return;
    }
    if(currentCurr.value === 'ETH'){
        if(Number(inputCurr) < 0.1){
          alert("ETH staking amount must be greater than 0.1")
          return
        }
        await walletETH.directBuyTokens(inputCurr, currentApr);
    }
    else{
      if(Number(inputCurr) < 10){
        alert("Altcoin staking amount must be at least 10")
        return
      }
      await walletETH.directBuyTokensUSDT(inputCurr, currentCurr.contract, currentCurr.decimals, currentApr);
    }
  }
  return (
    <div className="staking-container">
      <div className="network-info">
        <div className='title'>
          <img src='/img/hemi/hemi-logo.svg' />
          <h1>Staking</h1>  
        </div>
        <a href='/'>
          From Hemi Network
        </a>
      </div>
      <div className="dropdown-curr">
            <label className="label">You Sent: </label>
            <CurrencyDropdown/>
      </div>

      <div className="horizontal-array">
        {items.map((item, index) => (
          <div
            key={index}
            className={`array-item ${currentApr === item?.address ? 'selected' : ''}`}
            onClick={() => handleClick(item)}
          >
            <div className='label-period'>{item?.period}</div>
            <div className='label-apr'>{item?.apr}% APR</div>
            
          </div>
        ))}
      </div>
      {
            walletETH.currentAddress && 
            <div className="input-max">
              <input type="text" 
                    onChange={handleCurrencyInputChange}
                    onKeyPress={handleKeyPressCurr}
                    placeholder="Enter amount"  
                    value={inputCurr}/>
              <button className="max-button" onClick={getMaxAmount}>Max</button>
            </div>
      }
      <div>
          {
            walletETH.currentAddress && 
            <button className="confirm-button" onClick={handleStakeOnlick}>Confirm and Stake Asset</button>
          }

          {
              !walletETH.currentAddress && 
              <button className="connect-button" onClick={() => walletETH.connect()}>Connect Wallet</button>

          }   
      </div>
      
    </div>
  );
};

export default StakingForm;
