import React, { useState } from "react";
import "./BottomFooter.css";

const BottomFooter = ({ topOffset }) => {
 
  return (
    <footer>
        <div id="footer-container">
            <div id="footer-top">
                <div id="footer-top-left">
                    <div className="logo-wrapper">
                        <a href="https://hemi.xyz/" className="custom-logo-link" rel="home" aria-current="page">
                            <img width="93" height="32" src="https://hemi.xyz/wp-content/uploads/2024/04/hemi.svg" className="custom-logo" alt="Hemi" decoding="async" />
                        </a>
                        <div className="logo-subtitle">
                            Powered by Bitcoin and Ethereum
                        </div>
                    </div>
                    <div className="menu-social-container">
                        <ul id="menu-social" className="menu">
                            <li id="menu-item-96" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-96">
                                <a target="_blank" rel="noopener" href="https://x.com/hemi_xyz">
                                    <img src="https://hemi.xyz/wp-content/uploads/2024/04/twitter.svg" />
                                    <span>Hemi X account<span className="description"></span></span>
                                </a>
                            </li>
                            <li id="menu-item-97" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-97">
                                <a target="_blank" rel="noopener" href="https://discord.gg/hemixyz">
                                    <img src="https://hemi.xyz/wp-content/uploads/2024/04/discord.svg" />
                                    <span>Hemi Discord server<span className="description"></span></span>
                                </a>
                            </li>
                            <li id="menu-item-98" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-98">
                                <a target="_blank" rel="noopener" href="https://github.com/hemilabs">
                                    <img src="https://hemi.xyz/wp-content/uploads/2024/04/github.svg" />
                                    <span>Hemi GitHub account<span className="description"></span></span>
                                </a>
                            </li>
                            <li id="menu-item-99" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-99">
                                <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/hemi-labs">
                                    <img src="https://hemi.xyz/wp-content/uploads/2024/05/linkedin.svg" />
                                    <span>Hemi Linkedin account<span className="description"></span></span>
                                </a>
                            </li>
                            <li id="menu-item-580" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-580">
                                <a>
                                    <img src="https://hemi.xyz/wp-content/uploads/2024/08/telegram.svg" />
                                    <span>Hemi Telegram channels<span className="description"></span></span>
                                </a>
                                <ul className="sub-menu">
                                    <li id="menu-item-611" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-611">
                                        <a target="_blank" rel="noopener" href="https://t.me/hemi_community">Community Channel</a>
                                    </li>
                                    <li id="menu-item-612" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-612">
                                        <a target="_blank" rel="noopener" href="https://t.me/hemi_news">News Channel</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="footer-top-center">
                    <div>
                        <h3>Network</h3>
                        <div className="menu-footer-network-container"><ul id="menu-footer-network" className="menu"><li id="menu-item-480" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-480"><a href="/ecosystem">Ecosystem</a></li> <li id="menu-item-86" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86"><a target="_blank" rel="noopener" href="https://testnet.explorer.hemi.xyz/">Block explorer</a></li> </ul></div>        </div>
                    <div>
                        <h3>Company</h3>
                        <div className="menu-footer-company-container"><ul id="menu-footer-company" className="menu"><li id="menu-item-87" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-87"><a href="/about">About</a></li> <li id="menu-item-88" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-88"><a href="/blog">Blog</a></li> <li id="menu-item-91" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-91"><a href="mailto:careers@hemi.xyz">Careers</a></li> <li id="menu-item-441" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-441"><a target="_blank" rel="noopener" href="https://docs.hemi.xyz/additional-resources/brand-kit">Brand</a></li> </ul></div>        </div>
                    <div>
                        <h3>Resources</h3>
                        <div className="menu-footer-resources-container"><ul id="menu-footer-resources" className="menu"><li id="menu-item-92" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-92"><a target="_blank" rel="noopener" href="https://docs.hemi.xyz/">Docs</a></li> <li id="menu-item-93" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-93"><a target="_blank" rel="noopener" href="https://docs.hemi.xyz/building-bitcoin-apps/hemi-bitcoin-kit-hbk">Hemi Bitcoin Kit (hBK)</a></li> <li id="menu-item-95" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-95"><a target="_blank" rel="noopener" href="https://github.com/hemilabs">GitHub</a></li> <li id="menu-item-538" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-538"><a href="/changelog/">Changelog</a></li> </ul></div>        </div>
                </div>
                <div id="footer-top-right">
                    <h3>Subscribe for updates</h3>
                    <form id="form-66ed55b1b946b" className="form-subscription" action="#">
                        <input className="border-none outline-none focus:shadow-none focus:ring-0" type="email" />
                        <button type="submit">Submit</button>
                        <div className="success-message hide">
                            Thanks for subscribing!
                        </div>
                    </form>
                </div>

            </div>

            <div id="footer-bottom">
                <p>© 2024 Hemi Labs, Inc.</p>
                <div className="menu-legal-container"><ul id="menu-privacy-and-terms" className="menu"><li id="menu-item-73" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-73"><a href="/privacy-policy">Privacy</a></li> <li id="menu-item-74" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-74"><a href="/terms-of-service">Terms</a></li> </ul></div>    </div>
        </div>
    </footer>
  );
};

export default BottomFooter;
