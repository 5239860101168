import React, { useState } from 'react';
import './currencyDropdown.css'; // Import your CSS
import { CURR_LIST } from '../../ducks/currencySelectionDuck';
import { useCurrentCurr,useCurrentApr, useSetCurr } from '../../utils/currSelectionUtils';

const options = CURR_LIST

const CurrencyDropdown = () => {
  // const [currency, setCurrency] = useState('');
  const currentCurr = useCurrentCurr()
  
  const setCurrentCrr = useSetCurr()
  
  const [isOpen, setIsOpen] = useState(false);
  
  const handleSelect = (option) => {
    setCurrentCrr(option.value)
    setIsOpen(false);
  };


  return (
    <div className="dropdowns-wrapper">
      <div className="custom-dropdown" onClick={() => setIsOpen(!isOpen)}>
        <div className="selected-value">
          {currentCurr ? (
            <>
              <img src={currentCurr.icon} alt={currentCurr.label} className="icon" />
              {currentCurr.label}
            </>
          ) : (
            'Select currency'
          )}
        </div>
        {isOpen && (
          <ul className="options-list">
            {options.map((option) => (
              <li key={option.value} onClick={() => handleSelect(option)} className={`${currentCurr.value === option.value ? "selected-opt" : ""}`}>
                <img src={option.icon} alt={option.label} className="option-icon" />
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CurrencyDropdown;

