import { useSelector, useDispatch } from 'react-redux';
import {selectCurrentApr, selectCurrentCurr, selectCurrValue, setApr, setCurrency, setCurrValue} from '../ducks/currencySelectionDuck'

// Custom hook to select a specific section of i18n data
export const useCurrentCurr = () => {
  return useSelector(selectCurrentCurr);
};

// Custom hook to select a specific section of i18n data
export const useCurrentApr = () => {
  return useSelector(selectCurrentApr);
};

// Custom hook to select a specific section of i18n data
export const useCurrValue = () => {
  return useSelector(selectCurrValue);
};

  // Custom hook for setting speed
export const useSetCurr = () => {
    const dispatch = useDispatch();
    return (value) => {
      dispatch(setCurrency(value));
    };
};

  // Custom hook for setting speed
export const useSetApr = () => {
    const dispatch = useDispatch();
    return (address) => {
      dispatch(setApr(address));
    };
};


  // Custom hook for setting speed
export const useSetCurrValue = () => {
    const dispatch = useDispatch();
    return (value) => {
      dispatch(setCurrValue(value));
    };
};
